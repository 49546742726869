/* You can add global styles to this file, and also import other style files */
.screen-container {
  min-height: 100%;
  background-color: #0f1729;
  position: absolute;
  left: 0;
  right: 0;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

.dialog {
  max-height: 90%;
  overflow: auto;
}

.data-list-group .list-group-item {
    font-size: 0.9rem;
    padding: .3rem .5rem;
    line-height: 1.25rem;
}

.data-list-group .list-group-item h5{
    font-size: 1rem;
    margin-bottom: .3rem;
}

/* menu */

.menu-button {
    width: fit-content;
    position: absolute;
    top: 6px;
    right: 15px;
    background-color: #00000075;
    border-radius: 4px;
    color: #000;
    z-index: 10;
}

.bg-menu {
    background: #0e1d2f !important;
}

.cursor-pointer {
    cursor: pointer;
}


.video-target video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.v3 .video-target video {
  object-fit: cover;
}

.self video {
    transform: scaleX(-1);
}

body:fullscreen .d-fs-none {
  display: none !important;
}

body:fullscreen .d-fs-block {
  display: block !important;
}

/*
.screen-container.activeStudent {
    border: solid #ff2e49 7px;
}

.room-logo {
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 64px;
}

.room-logo img {
    height: 100%;
}

.timer-teacher {
    width: 250px;
}

.timer-student {
    width: 240px;
}

.timer {
    position: absolute;
    right: 0;
    bottom: 15px;
    color: #000;
    font-size: 26px;
    line-height: 28px;
    font-weight: 800;
    background: #ffffff50;
    padding: 2px 8px;
    border-radius: 5px 0 0 5px;
    text-align: center;
}

.timer .room-name {
    font-size: 14px;
    padding: 0px 0px;
    line-height: 16px;
    display: block;
}*/

.capture-devices-page-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
}

.capture-devices-page-wrapper div.card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.version {
    position: absolute;
    right: 1px;
    bottom: 1px;
    color: #fff;
    font-size: 9px;
}

.charts-close {
    font-size: 24px;
    float: left;
}

.chart-select {
    width: 100%;
    float: right;
    margin-top: 10px;
}

.chat {
    background-color: #f3f7fd;
    padding: 5px 5px 5px 5px;
}

.book-renderer {
  background: #808080;
  position: fixed;
  top: 90px;
  bottom: 0px;
  left: -350px;
  width: 350px;
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: #00000073 0px 0px 5px;
}

.bookActive .book-renderer {
  left: 5px;
}


.toolbox {
    position: fixed;
  z-index: 1000;
    top: 0;
    right: -390px;
    width: 390px;
    background: #2f5686;
    bottom: 0px;
    padding: 15px 15px 15px 15px;
    overflow-y: auto;
    color: #fff;
    transition: 0.4s;
    box-shadow: #0000008c -3px 0px 10px -2px;
    border-left: #ffffff40 solid 1px;
}

.toolbox.show {
    right: 0;
}

.toolbox a {
    color: #97c3f3;
}

.toolbox a:hover {
    color: #89a6c5;
}

.toolbox .list-group {
    color: #313131;
}



/** overrides **/

.teacher-video app-participant-video .video-target video {
  width: unset;
}

/** utils **/

.pointer-events-none {
  pointer-events: none;
}



